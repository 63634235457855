.App {
  text-align: center;
  background-color: whitesmoke;
  height: 100vh;
}

.Controller {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 86vh;
    padding-left: 5%;
    padding-right: 5%;
  }

.login-paper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-button {
    display: flex;
}


.login-grid {
    height: 80vh;
    width: 85vh;
    padding-top: 25%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 15%;
    background-color: white;
    font-size: calc(10px + 2vmin);
    color: #282c34;
}

.dashboard-grid {
    max-height: 80vh;
    background-color: rgb(92, 100, 74);
    font-size: calc(10px + 2vmin);
    color: #282c34;
}